import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { MessageCircleWarning } from "lucide-react";
import { cn } from "@/lib/utils";

type SidebarAlertProps = {
  setShowPaywall: (show: boolean) => void;
  userSubscription: UserSubscription | null | undefined;
  isCollapsed?: boolean;
};

const SidebarAlert = ({
  setShowPaywall,
  userSubscription,
  isCollapsed = false,
}: SidebarAlertProps) => {
  const navigate = useNavigate();

  if (!userSubscription) {
    return (
      <motion.div
        style={{ width: "100%" }}
        className={cn(
          "mb-2 flex flex-col gap-1.5 rounded-sm border border-border p-3",
          isCollapsed && "items-center border-0 p-0",
        )}
      >
        {!isCollapsed && (
          <>
            <h6 className="font-bricolage-medium">Start Your Journey</h6>
            <p className="text-sm text-muted-foreground">
              Start your subscription now and cancel anytime.
            </p>
          </>
        )}
        <Button
          size={isCollapsed ? "icon" : "sm"}
          className={isCollapsed ? "h-8 w-8" : "mt-2"}
          onClick={() => setShowPaywall(true)}
        >
          {isCollapsed ? "$" : "Start Subscription"}
        </Button>
      </motion.div>
    );
  }

  if (userSubscription.status === "past_due") {
    return (
      <motion.div
        style={{ width: "100%" }}
        className={cn(
          "mb-2 flex flex-col gap-1.5 rounded-sm border border-border p-3",
          isCollapsed && "items-center border-0 p-0",
        )}
      >
        {!isCollapsed && (
          <>
            <h6 className="flex flex-row items-center gap-1 font-bricolage-medium">
              <MessageCircleWarning
                size={16}
                strokeWidth={2.5}
                className="text-destructive"
              />
              Overdue Payment
            </h6>
            <p className="text-xs text-muted-foreground">
              Your subscription has an overdue payment. Please update your
              payment method to not lose access to our services.
            </p>
          </>
        )}
        <Button
          size={isCollapsed ? "icon" : "sm"}
          className={
            isCollapsed
              ? "size-9 border border-border bg-transparent hover:bg-transparent"
              : "mt-2"
          }
          onClick={() => navigate("/account/billing")}
        >
          {isCollapsed ? (
            <MessageCircleWarning
              size={16}
              strokeWidth={2.5}
              className="text-destructive"
            />
          ) : (
            "Update Payment Method"
          )}
        </Button>
      </motion.div>
    );
  }

  return null;
};

export default SidebarAlert;
