import MediaUploadDialog from "@/views/Media/components/Dialog/MediaUploadDialog";
import MediaDataTable from "@/views/Media/components/Table/MediaDataTable";

const MediaView = () => {
  return (
    <div className="space-y-3">
      <div className="flex flex-row items-center justify-between">
        <div className="space-y-0.5">
          <h1 className="font-bricolage-semibold text-2xl md:text-3xl">
            Media Library
          </h1>
          <p className="text-sm text-muted-foreground sm:block hidden">
            Import any audio or media to use in your Spaces.
          </p>
        </div>
        <MediaUploadDialog />
      </div>
      <div className="py-4">
        <MediaDataTable />
      </div>
    </div>
  );
};

export default MediaView;
