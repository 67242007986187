import { useState } from "react";
import { mutate } from "swr";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import Alert from "@/components/Alert/Alert";
import { LockIcon, AtSign } from "lucide-react";
import GoogleLogoUrl from "@/assets/images/google-oauth-logo.svg";

import { isValidRoute } from "@/lib/route";
import { useAuth } from "@/views/Auth/AuthProvider";
import { usersMeUrl } from "@/api/hooks/useUser";

import { handleEmailLogin } from "@/api/services/auth";
import { handleGoogleAuth } from "@/api/services/oauth";
import { setLastLogin } from "@/lib/auth/lastLogin";
import { STATUS } from "@/api/utils";

const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  password: z.string().min(1, { message: "Password is required" }),
});

type formSchema = z.infer<typeof formSchema>;

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(STATUS.IDLE);
  const { refreshUser } = useAuth();

  const form = useForm<formSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<formSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      await handleEmailLogin(data);
      setLastLogin(data.email);

      await refreshUser();
      mutate(usersMeUrl);

      setStatus(STATUS.SUCCESS);

      const nextPath = searchParams.get("next");
      if (nextPath && isValidRoute(nextPath)) {
        navigate(nextPath, { replace: true });
      } else {
        navigate("/spaces", { replace: true });
      }
    } catch (error) {
      form.setError("root", { message: (error as Error).message });
      setStatus(STATUS.ERROR);
    }
  };

  const handleGoogleLogin = async () => {
    setStatus(STATUS.LOADING);

    try {
      const authorization_url = await handleGoogleAuth();
      window.location.href = authorization_url;
    } catch (error) {
      form.setError("root", { message: (error as Error).message });
      setStatus(STATUS.ERROR);
    }
  };

  const alertMessage = location.state?.message || undefined;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        {form.formState.errors.root ? (
          <Alert type="error" message={form.formState.errors.root.message!} />
        ) : (
          alertMessage && <Alert type="success" message={alertMessage} />
        )}
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  startIcon={AtSign}
                  placeholder="Enter your email"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <div className="flex flex-row justify-between">
                <FormLabel>Password</FormLabel>
                <Link
                  to="/forgot-password"
                  className="text-sm underline hover:text-primary"
                >
                  Forgot password?
                </Link>
              </div>

              <FormControl>
                <Input
                  type="password"
                  placeholder="Enter your password"
                  startIcon={LockIcon}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          isLoading={status === STATUS.LOADING}
          className="mt-4 w-full"
        >
          Log in
        </Button>
      </form>
      <Separator />
      <div>
        <Button
          variant="outline"
          className="w-full"
          onClick={handleGoogleLogin}
          disabled={status === STATUS.LOADING}
        >
          <img src={GoogleLogoUrl} alt="Google logo" className="mr-2 h-5 w-5" />
          Login with Google
        </Button>
      </div>
    </Form>
  );
};

export default LoginForm;
