import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Plus } from "lucide-react";

import NewSpaceForm from "@/views/Spaces/components/Form/NewSpaceForm";

type NewSpaceDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
};

const NewSpaceDialog = ({ dialogOpen, setDialogOpen }: NewSpaceDialogProps) => {
  const location = useLocation();
  const [step, setStep] = useState<1 | 2>(1);

  const handleNextStep = () => {
    setStep(2);
  };

  const handlePrevStep = () => {
    setStep(1);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setStep(1);
  };

  useEffect(() => {
    if (location.state?.newSpace) {
      setDialogOpen(true);
    }
  }, [location, setDialogOpen]);

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button size="sm">
          <Plus className="mr-2 h-4 w-4" />
          New Space
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-screen-sm">
        <DialogHeader>
          <DialogTitle>
            {step === 1 ? "New Space" : "Select Content Preset"}
          </DialogTitle>
          <DialogDescription>
            {step === 1
              ? "Tell us about your new space."
              : "Pick a starting content preset to guide the Reinspire AI in creating your content. You'll be able to customize this later."}
          </DialogDescription>
        </DialogHeader>
        <NewSpaceForm
          setDialogOpen={handleClose}
          onNextStep={handleNextStep}
          onPrevStep={handlePrevStep}
          currentStep={step}
        />
      </DialogContent>
    </Dialog>
  );
};

export default NewSpaceDialog;
