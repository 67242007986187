import { useState, useEffect } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { CircleX } from "lucide-react";
import Loader from "@/components/Loaders/Loader";

import { STATUS } from "@/api/utils";
import { getGoogleOauthCallback } from "@/api/services/oauth";
import { setLastLogin } from "@/lib/auth/lastLogin";
import { useAuth } from "@/views/Auth/AuthProvider";

const OauthView = () => {
  const navigate = useNavigate();
  const { refreshUser } = useAuth();

  const [status, setStatus] = useState(STATUS.LOADING);
  const [oauthError, setOauthError] = useState<string>("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const abortController = new AbortController();

    const state = searchParams.get("state");
    const code = searchParams.get("code");
    const error = searchParams.get("error");

    if (!code || !state) {
      setStatus(STATUS.ERROR);

      if (error) {
        setStatus(STATUS.ERROR);
        setOauthError(error);
        return;
      }

      setOauthError("Invalid oauth request");
      return;
    }

    const getCallback = async () => {
      try {
        await getGoogleOauthCallback(
          { code: code, state: state, error: error },
          abortController,
        );

        setLastLogin("google");
        await refreshUser();

        setStatus(STATUS.SUCCESS);
        navigate("/spaces", { replace: true });
      } catch (err) {
        const error = err as Error;
        if (error.message !== "canceled") {
          setStatus(STATUS.ERROR);
          setOauthError(error.message);
        }
      }
    };

    getCallback();

    return () => {
      abortController.abort();
    };
  }, [searchParams]);

  if (status === STATUS.IDLE || status === STATUS.LOADING) {
    return <Loader />;
  }

  if (status === STATUS.ERROR) {
    return (
      <div className="flex flex-col items-center justify-center gap-3 text-center">
        <CircleX size={32} strokeWidth={2.5} className="text-destructive" />
        <h6 className="text-destructive">{oauthError}</h6>
        <p className="text-sm">
          Try authenticating with Google again. If the problem persists, please
          contact support.
        </p>
        <Button asChild>
          <Link to="/login">Go to login page</Link>
        </Button>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center">
      <p className="text-success">Oauth successful. Redirecting ...</p>
    </div>
  );
};

export default OauthView;
