import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Loader2 } from "lucide-react";

import { handleEmailVerify, UserAlreadyVerified } from "@/api/services/verify";
import { STATUS } from "@/api/utils";

const VerifyEmailView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [status, setStatus] = useState(STATUS.ERROR);
  const [verifyError, setVerifyError] = useState<string>("");

  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      setStatus(STATUS.ERROR);
      setVerifyError("Invalid token. Please try again.");
    }

    setStatus(STATUS.LOADING);

    const verifyEmail = async () => {
      try {
        await handleEmailVerify(token ?? "");
        setStatus(STATUS.SUCCESS);
        navigate("/login", {
          state: { message: "Email verified successfully." },
        });
      } catch (error) {
        if (error instanceof UserAlreadyVerified) {
          setStatus(STATUS.SUCCESS);
          navigate("/login", {
            state: { message: "Email already verified." },
          });
          return;
        }

        setStatus(STATUS.ERROR);
        setVerifyError((error as Error).message);
      }
    };

    verifyEmail();
  }, [token]);

  if (status === STATUS.IDLE || status === STATUS.LOADING) {
    return (
      <div className="flex items-center justify-center">
        <Loader2 size={52} className="animate-spin text-primary" />
      </div>
    );
  }

  if (status === STATUS.ERROR) {
    return (
      <div className="flex flex-col items-center justify-center gap-3 text-center">
        <p className="text-destructive">{verifyError}</p>
        <p className="text-sm">Try verifying your email again.</p>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center">
      <p className="text-success">
        Email verified successfully. Redirecting ...
      </p>
    </div>
  );
};

export default VerifyEmailView;
