import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { CircleCheckBig } from "lucide-react";
import { Paddle } from "@paddle/paddle-js";

import ChangePlanDialog from "@/views/Account/components/Dialog/ChangePlanDialog";

import { STATUS } from "@/api/utils";
import { useAuth } from "@/views/Auth/AuthProvider";
import { cn } from "@/lib/utils";

type BillingPlanCardProps = {
  priceId: string;
  title: string;
  price: number;
  period: "monthly" | "yearly";
  features: {
    icon: React.ReactNode;
    feature: string;
  }[];
  activeSubscription: Subscription | null;
  isUserSubscribed: boolean;
  discount?: number;
  paddle?: Paddle;
  onBeforeCheckout?: () => void;
};

const BillingPlanCard = (props: BillingPlanCardProps) => {
  const {
    priceId,
    title,
    price,
    period,
    features,
    activeSubscription,
    isUserSubscribed,
    discount,
    paddle,
    onBeforeCheckout,
  } = props;
  const navigate = useNavigate();
  const { authState } = useAuth();

  const [status, setStatus] = useState(STATUS.IDLE);

  const onPlanSelect = () => {
    if (!paddle) return;

    if (onBeforeCheckout) {
      onBeforeCheckout();
    }

    setStatus(STATUS.LOADING);
    paddle.Checkout.open({
      items: [{ priceId: priceId, quantity: 1 }],
      customer: {
        email: authState.currentUser!.email,
      },
      settings: {
        allowLogout: false,
        allowDiscountRemoval: false,
        successUrl: `${window.location.origin}/account/billing?success=true`,
      },
      customData: {
        user_id: authState.currentUser!.id,
      },
    });
    setStatus(STATUS.SUCCESS);
  };

  const onActivePlanSelect = () => {
    if (onBeforeCheckout) {
      onBeforeCheckout();
    }

    navigate("/account/billing#subscription");
  };

  return (
    <div className="flex min-w-[300px] flex-col space-y-6 rounded-sm border border-border bg-card p-4">
      <div className="flex flex-shrink-0 flex-row items-center justify-between">
        <h3 className="font-bricolage-semibold text-xl">{title}</h3>
        {activeSubscription ? (
          <Badge
            variant="outline"
            className={cn(
              "font-bricolage-medium",
              activeSubscription.status === "active"
                ? "border-green-600 bg-green-600/20 text-green-600"
                : activeSubscription.status === "past_due"
                ? "border-yellow-500 bg-yellow-500/20 text-yellow-500"
                : activeSubscription.status === "canceled" &&
                  "border-red-500 bg-red-500/20 text-red-500",
            )}
          >
            {activeSubscription.status.charAt(0).toUpperCase() +
              activeSubscription.status.slice(1)}
          </Badge>
        ) : (
          discount && <Badge className="font-rubik-regular">-{discount}%</Badge>
        )}
      </div>
      <div className="flex flex-shrink-0 flex-col gap-0.5 text-start">
        <div className="space-x-1">
          <span className="font-bricolage-semibold text-4xl">
            ${period === "yearly" ? price / 12 : price}
          </span>
          <span className="font-bricolage-regular text-sm">/ month</span>
        </div>
        <div>
          {period === "yearly" && (
            <span className="text-sm">${price} billed annually</span>
          )}
        </div>
      </div>
      <div className="flex-grow">
        <ul className="flex flex-col gap-2.5 pl-1">
          {features.map((item, index) => (
            <li key={index} className="flex flex-row items-center">
              {item.icon ? (
                <span className="mr-2 text-primary">{item.icon}</span>
              ) : (
                <CircleCheckBig
                  size={18}
                  strokeWidth={2.5}
                  className="mr-2 text-green-600"
                />
              )}

              {item.feature}
            </li>
          ))}
        </ul>
      </div>
      {activeSubscription ? (
        <Button
          className="!mt-10 w-full flex-shrink-0"
          onClick={onActivePlanSelect}
        >
          Manage Subscription
        </Button>
      ) : isUserSubscribed ? (
        <ChangePlanDialog planName={title} priceId={priceId} />
      ) : (
        <Button
          className="!mt-10 w-full flex-shrink-0"
          isLoading={status === STATUS.LOADING}
          onClick={onPlanSelect}
        >
          Select plan
        </Button>
      )}
    </div>
  );
};

export default BillingPlanCard;
