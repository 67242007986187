import { useAppContext } from "@/app/AppContextProvider";
import { getPaymentUpdateTransaction } from "@/api/services/subscription";

interface CardDetails {
  type: string;
  last4: string;
  expiry_year: number;
  expiry_month: number;
  cardholder_name: string;
}

interface PaymentMethodDetails {
  card: CardDetails | null;
  type: string;
}

interface PaymentDetailsCardProps {
  setDialogOpen: (open: boolean) => void;
  payment_method_details: PaymentMethodDetails | null;
}

const PaymentDetailsCard = (props: PaymentDetailsCardProps) => {
  const { paddle } = useAppContext();
  const { setDialogOpen, payment_method_details } = props;

  const handleUpdatePaymentMethod = async () => {
    if (!paddle) return;

    try {
      const paymentTransaction = await getPaymentUpdateTransaction();
      paddle.Checkout.open({
        transactionId: paymentTransaction.transaction_id,
      });
      setDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (!payment_method_details) {
    return (
      <div className="space-y-2 rounded-lg border border-border p-4">
        <h6 className="font-semibold">Payment Details</h6>
        <p className="text-muted-foreground">
          No payment information available.
        </p>
      </div>
    );
  }

  const { type, card } = payment_method_details;

  return (
    <div className="space-y-2 rounded-lg border border-border p-4">
      <div className="flex flex-row items-center justify-between">
        <h6 className="font-semibold">Payment Details</h6>
        <button
          onClick={handleUpdatePaymentMethod}
          className="text-sm text-primary underline"
        >
          Update
        </button>
      </div>
      <div className="space-y-3">
        <div className="flex items-center">
          <span className="w-1/2 font-medium text-muted-foreground md:w-1/3">
            Payment Type:
          </span>
          <span className="capitalize">{type}</span>
        </div>

        {type === "card" && card ? (
          <div className="text-base">
            <div className="flex items-center">
              <span className="w-1/2 font-medium text-muted-foreground md:w-1/3">
                Card Type:
              </span>
              <span className="capitalize ">{card.type}</span>
            </div>
            <div className="flex items-center">
              <span className="w-1/2 font-medium text-muted-foreground md:w-1/3">
                Card Number:
              </span>
              <span>•••• •••• •••• {card.last4}</span>
            </div>
            <div className="flex items-center">
              <span className="w-1/2 font-medium text-muted-foreground md:w-1/3">
                Expiry Date:
              </span>
              <span>
                {card.expiry_month.toString().padStart(2, "0")}/
                {card.expiry_year}
              </span>
            </div>
            <div className="flex items-center">
              <span className="w-1/2 font-medium text-muted-foreground md:w-1/3">
                Cardholder Name:
              </span>
              <span>{card.cardholder_name}</span>
            </div>
          </div>
        ) : (
          <p className="text-muted-foreground">
            Additional details not available for this payment method.
          </p>
        )}
      </div>
    </div>
  );
};

export default PaymentDetailsCard;
