import moment from "moment";

export function formatDate(date: Date | string, format: string) {
  return moment.utc(date).local().format(format);
}

export function formatRelativeTime(date: Date | string) {
  return moment.utc(date).local().fromNow();
}

export function isPastCurrentDate(date: Date | string) {
  return moment(date).isBefore(moment(new Date()));
}

export function isSameDay(date1: Date | string, date2: Date | string) {
  return moment(date1).isSame(date2, "day");
}

export function convertSecondsToTimestamp(seconds: number) {
  return moment.utc(seconds * 1000).format("HH:mm:ss");
}
