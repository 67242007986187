import { useRef } from "react";
import { AxiosError } from "axios";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { MediaPlayerInstance } from "@vidstack/react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Sparkles,
  RefreshCw,
  CircleDashed,
  MoveLeft,
  Loader2,
} from "lucide-react";

import TabButton from "@/components/Button/TabButton";
import TranscribeButton from "@/views/Recordings/components/Button/TranscribeButton";
import RecordingAudioPlayer from "@/views/Recordings/components/Player/RecordingAudioPlayer";

import { useRecording } from "@/api/hooks/useRecordings";

export type RecordingContextType = {
  recording: Recording;
  player: React.RefObject<MediaPlayerInstance>;
};

const RecordingLayout = () => {
  const navigate = useNavigate();
  const { spaceSlug, recordingId } = useParams();
  if (!spaceSlug || !recordingId)
    throw new Error("Space slug and recording ID are required");

  const player = useRef<MediaPlayerInstance>(null);

  const { recording, recordingLoading, recordingError } = useRecording(
    spaceSlug,
    recordingId,
  );

  // If the recording does not exist, show a 404 page
  if (recordingError) {
    const error = recordingError as AxiosError;
    if ([404, 422].includes(error.response?.status as number)) {
      return (
        <div className="flex w-full flex-col items-center justify-center px-8 py-16 md:ml-16 md:px-16">
          <h2 className="text-2xl font-bold">Recording not found</h2>
          <p className="mt-4 text-center text-sm text-muted-foreground">
            The recording you are looking for does not exist.
          </p>
          <Button size="sm" asChild className="mt-4">
            <Link to={`/spaces/${spaceSlug}/recordings`}>
              Go back to recordings
            </Link>
          </Button>
        </div>
      );
    }
  }

  if (recordingLoading) {
    return (
      <div className="space-y-3">
        <div className="flex flex-row items-center justify-between space-y-0.5">
          <Skeleton className="h-6 w-72" />
        </div>
        <div className="flex h-48 items-center justify-center">
          <Loader2
            size={36}
            strokeWidth={2.5}
            className="animate-spin text-primary"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-3">
      <div className="flex flex-row items-center justify-between space-y-0.5">
        <div className="flex flex-row items-center gap-2 sm:gap-4">
          <Button
            variant="outline"
            className="size-8 min-w-8 sm:size-9 sm:min-w-9 bg-card p-0"
            onClick={() => navigate(`/spaces/${spaceSlug}/recordings`)}
          >
            <MoveLeft size={18} strokeWidth={2.5} />
          </Button>
          <h1 className="line-clamp-1 font-bricolage-semibold text-2xl sm:text-3xl">
            {recording!.media.title}
          </h1>
        </div>
      </div>
      {recording!.transcript_status === "completed" ? (
        <>
          <nav className="relative flex flex-row gap-8 border-b border-border">
            <TabButton
              text="Transcript"
              to={`/spaces/${spaceSlug}/recordings/${recordingId}/transcript`}
            />
            <TabButton
              text={
                <span className="flex flex-row items-center gap-1.5">
                  <Sparkles
                    size={15}
                    strokeWidth={2}
                    fill="#eab308"
                    className="text-yellow-500"
                  />
                  AI Content
                </span>
              }
              to={`/spaces/${spaceSlug}/recordings/${recordingId}/content`}
            />
          </nav>
          <div className="pb-16">
            <Outlet
              context={
                {
                  recording: recording!,
                  player: player,
                } satisfies RecordingContextType
              }
            />
          </div>
        </>
      ) : (
        <div className="!mt-4 rounded-sm border border-border bg-card p-4">
          {recording!.transcript_status === "not_started" ? (
            <div className="flex flex-row items-center justify-between">
              <div>
                <h4 className="font-bold">Missing transcript</h4>
                <p className="text-sm text-muted-foreground">
                  This recording has not been transcribed yet. If you reached
                  your usage limit, please upgrade your plan to continue.
                </p>
              </div>
              <TranscribeButton
                recordingId={recording!.id}
                className="min-w-28"
              />
            </div>
          ) : recording!.transcript_status === "pending" ? (
            <div className="flex flex-row items-center gap-1.5">
              <CircleDashed
                size={15}
                strokeWidth={2.5}
                className="animate-pulse text-primary"
              />
              <span>Transcription pending</span>
            </div>
          ) : recording!.transcript_status === "processing" ? (
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-1.5">
                <RefreshCw
                  size={15}
                  strokeWidth={2.5}
                  className="animate-spin text-muted-foreground"
                />
                <span>Transcribing...</span>
              </div>
              <span className="text-sm text-muted-foreground">
                This usually takes a few minutes. Please come back later.
              </span>
            </div>
          ) : (
            recording!.transcript_status === "failed" && (
              <div>
                <h4 className="font-bold text-destructive">
                  Transcription failed
                </h4>
                <p className="text-sm text-muted-foreground">
                  The transcription process failed. Our team is working on it.
                  Please reach out to support for further assistance.
                </p>
              </div>
            )
          )}
        </div>
      )}

      <RecordingAudioPlayer recording={recording!} player={player} />

      {/* Crisp Chatbox position override workaround */}
      <style>{`
        #crisp-chatbox div a:first-of-type {
          bottom: 80px !important;
        }
      `}</style>
    </div>
  );
};

export default RecordingLayout;
