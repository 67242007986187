export function postProcessOutput(output: string): string {
  const timestampRegex = /(\d{2}:\d{2}:\d{2}) --> (\d{2}:\d{2}:\d{2})/g;

  const createTimestampComponent = (timestamp: string): string => {
    return `<Timestamp>${timestamp}</Timestamp>`;
  };

  return output.replace(timestampRegex, (_, start, end) => {
    return `${createTimestampComponent(start)} – ${createTimestampComponent(
      end,
    )}`;
  });
}
