import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import Alert from "@/components/Alert/Alert";
import { Check, X, LockIcon, AtSign, User } from "lucide-react";
import GoogleLogoUrl from "@/assets/images/google-oauth-logo.svg";

import { handleEmailRegister } from "@/api/services/auth";
import { handleGoogleAuth } from "@/api/services/oauth";
import { STATUS } from "@/api/utils";

const formSchema = z.object({
  full_name: z
    .string()
    .min(3, "Full name must be at least 3 characters long")
    .max(120, "Full name must be at most 120 characters long"),
  email: z.string().email({ message: "Invalid email address" }),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters long")
    .regex(/[A-Z]/, "Password must contain at least 1 uppercase letter")
    .regex(/[0-9]/, "Password must contain at least 1 number"),
});

type formSchema = z.infer<typeof formSchema>;

type RegisterFormProps = {
  status: string;
  setStatus: (status: string) => void;
  setEmail: (email: string) => void;
};

const RegisterForm = ({ status, setStatus, setEmail }: RegisterFormProps) => {
  const [passwordChecks, setPasswordChecks] = useState({
    length: false,
    number: false,
    uppercase: false,
  });

  const form = useForm<formSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      full_name: "",
      email: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<formSchema> = async (data) => {
    setStatus(STATUS.LOADING);

    try {
      await handleEmailRegister(data);
      setStatus(STATUS.SUCCESS);
      setEmail(data.email);
    } catch (error) {
      form.setError("root", { message: (error as Error).message });
      setStatus(STATUS.ERROR);
    }
  };

  const handleGoogleLogin = async () => {
    setStatus(STATUS.LOADING);

    try {
      const authorization_url = await handleGoogleAuth();
      window.location.href = authorization_url;
    } catch (error) {
      form.setError("root", { message: (error as Error).message });
      setStatus(STATUS.ERROR);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-2"
      >
        {form.formState.errors.root && (
          <Alert type="error" message={form.formState.errors.root.message!} />
        )}
        <FormField
          control={form.control}
          name="full_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Full Name</FormLabel>
              <FormControl>
                <Input
                  startIcon={User}
                  placeholder="Enter your full name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  startIcon={AtSign}
                  placeholder="Enter your email"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input
                  type="password"
                  placeholder="Enter your password"
                  startIcon={LockIcon}
                  showEye={true}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    const password = e.target.value;
                    setPasswordChecks({
                      length: password.length >= 8,
                      number: /[0-9]/.test(password),
                      uppercase: /[A-Z]/.test(password),
                    });
                  }}
                />
              </FormControl>
              <div className="!mt-2 flex flex-col gap-0.5 text-sm">
                <span>Password must contain at least:</span>
                <ul className="flex flex-row gap-2 flex-wrap">
                  <li className="flex flex-row items-center">
                    {passwordChecks.length ? (
                      <Check
                        size={16}
                        strokeWidth={2.5}
                        className="mr-0.5 text-green-600"
                      />
                    ) : (
                      <X
                        size={16}
                        strokeWidth={2.5}
                        className="mr-0.5 text-destructive"
                      />
                    )}
                    8 characters
                  </li>
                  <li className="flex flex-row items-center">
                    {passwordChecks.number ? (
                      <Check
                        size={16}
                        strokeWidth={2.5}
                        className="mr-0.5 text-green-600"
                      />
                    ) : (
                      <X
                        size={16}
                        strokeWidth={2.5}
                        className="mr-0.5 text-destructive"
                      />
                    )}
                    One number
                  </li>
                  <li className="flex flex-row items-center">
                    {passwordChecks.uppercase ? (
                      <Check
                        size={16}
                        strokeWidth={2.5}
                        className="mr-0.5 text-green-600"
                      />
                    ) : (
                      <X
                        size={16}
                        strokeWidth={2.5}
                        className="mr-0.5 text-destructive"
                      />
                    )}
                    One capital letter
                  </li>
                </ul>
              </div>
            </FormItem>
          )}
        />

        <Button
          type="submit"
          isLoading={status === STATUS.LOADING}
          className="mt-4 w-full"
        >
          Sign up
        </Button>
      </form>
      <Separator />
      <div>
        <Button
          variant="outline"
          className="w-full"
          onClick={handleGoogleLogin}
          disabled={status === STATUS.LOADING}
        >
          <img src={GoogleLogoUrl} alt="Google logo" className="mr-2 h-5 w-5" />
          Sign up with Google
        </Button>
      </div>
    </Form>
  );
};

export default RegisterForm;
