import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { mutate } from "swr";
import { useAppContext } from "@/app/AppContextProvider";
import { useAuth } from "@/views/Auth/AuthProvider";

import Alert from "@/components/Alert/Alert";
import UserSubscription from "@/views/Account/components/Section/UserSubscription";
import BillingPlans from "@/views/Account/components/Section/BillingPlans";

import { usersMeUrl } from "@/api/hooks/useUser";
import {
  userSubscriptionUrl,
  useSubscription,
} from "@/api/hooks/useSubscription";

const BillingView = () => {
  const [searchParams] = useSearchParams();
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const { refreshUser } = useAuth();
  const { paddle } = useAppContext();

  const { subscription, subscriptionLoading } = useSubscription();

  useEffect(() => {
    const successParam = searchParams.get("success");
    if (!successParam) return;

    setCheckoutSuccess(true);
    const timer = setTimeout(() => {
      mutate(userSubscriptionUrl);
      mutate(usersMeUrl);
      refreshUser();
    }, 5000);

    return () => clearTimeout(timer);
  }, [searchParams]);

  useEffect(() => {
    if (!subscription) return;
    if (!checkoutSuccess) return;

    setCheckoutSuccess(false);
  }, [subscription]);

  return (
    <div className="max-w-full space-y-16">
      {checkoutSuccess && (
        <Alert
          type="success"
          message="Your subscription was successful. It may take a few minutes to reflect in your account."
          isDismissible={true}
        />
      )}
      <UserSubscription />
      <BillingPlans
        subscription={subscription}
        subscriptionLoading={subscriptionLoading}
        paddle={paddle}
      />
    </div>
  );
};

export default BillingView;
