import { useState } from "react";

import SpacesTable from "@/views/Spaces/components/Table/SpacesTable";
import NewSpaceDialog from "@/views/Spaces/components/Dialog/NewSpaceDialog";

const SpacesView = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <div className="space-y-3">
      <div className="flex flex-row items-center justify-between">
        <h1 className="font-bricolage-semibold text-2xl md:text-3xl">Spaces</h1>
        <NewSpaceDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
      </div>
      <div className="py-4">
        <SpacesTable setDialogOpen={setDialogOpen} />
      </div>
    </div>
  );
};

export default SpacesView;
