import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { ChevronRight } from "lucide-react";
import { Crisp } from "crisp-sdk-web";
import { cn } from "@/lib/utils";

import { handleLogout } from "@/api/services/auth";
import { extractInitials } from "@/lib/utils";
import { useUser } from "@/api/hooks/useUser";

type UserDropdownProps = {
  isMobile?: boolean;
  isCollapsed?: boolean;
  onDropdownItemClick?: () => void;
};

const UserDropdown = ({
  isMobile,
  isCollapsed,
  onDropdownItemClick,
}: UserDropdownProps) => {
  const { user } = useUser();

  const logoutUser = async () => {
    try {
      await handleLogout();
      Crisp.chat.hide();
      // Redirect to login page
      window.location.href = "/login";
    } catch (error) {
      console.error("Logout error: ", error);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row gap-2">
            <Avatar className="flex-shrink-0">
              <AvatarFallback>
                {extractInitials(user?.full_name || "")}
              </AvatarFallback>
            </Avatar>
            <div
              className={cn(
                "flex flex-col justify-center overflow-hidden text-nowrap text-start",
                isCollapsed ? "hidden" : "flex",
              )}
            >
              <span className="max-w-[150px] overflow-hidden text-ellipsis text-nowrap font-bricolage-semibold text-sm">
                {user?.full_name ? user.full_name.split(" ")[0] : ""}
              </span>
              <small className="font-bricolage-regular text-primary">
                {user?.subscription ? user?.subscription.plan : "Free"} plan
              </small>
            </div>
          </div>

          <ChevronRight
            size={18}
            strokeWidth={2.5}
            className={cn(
              "flex-shrink-0 text-muted-foreground",
              isCollapsed ? "hidden" : "block",
              isMobile ? "rotate-90" : "rotate-0",
            )}
          />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent side={isMobile ? "bottom" : "right"} align="end">
        <DropdownMenuLabel className="flex flex-col">
          <span className="font-bricolage-light text-muted-foreground">
            Signed in as
          </span>
          <span className="font-bricolage-regular">{user?.email}</span>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild className="cursor-pointer">
          <Link to="/account/settings" onClick={onDropdownItemClick}>
            Account
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild className="cursor-pointer">
          <Link to="/account/billing" onClick={onDropdownItemClick}>
            Billing
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="cursor-pointer !text-destructive"
          onClick={logoutUser}
        >
          Log out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserDropdown;
