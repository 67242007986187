import { Construction } from "lucide-react";
import { Badge } from "@/components/ui/badge";

const IntegrationsView = () => {
  return (
    <div className="flex items-center justify-center py-20">
      <div className="flex w-full md:w-2/3 flex-col items-center justify-center gap-4 ">
        <Construction size={72} strokeWidth={2.5} className="text-primary" />
        <h2 className="font-bricolage-medium text-2xl">Integrations</h2>
        <Badge variant="outline" size="md">
          Coming Soon
        </Badge>
        <p className="text-center text-muted-foreground">
          Seamlessly integrate Reinspire to your favorite tools and automate
          your workflows. Simplify content creation and share effortlessly on
          social media platforms.
        </p>
      </div>
    </div>
  );
};

export default IntegrationsView;
