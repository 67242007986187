import { useState } from "react";
import { Link } from "react-router-dom";

import ForgotPasswordCard from "@/views/Auth/components/Card/ForgotPasswordCard";
import ForgotPasswordForm from "@/views/Auth/components/Form/ForgotPasswordForm";

import { STATUS } from "@/api/utils";

import LogoUrl from "@/assets/images/logo-square.png";

const ForgotPasswordView = () => {
  const [status, setStatus] = useState(STATUS.IDLE);
  const [userEmail, setUserEmail] = useState<string | null>(null);

  if (status === STATUS.SUCCESS) {
    return <ForgotPasswordCard email={userEmail!} />;
  }

  return (
    <div className="space-y-4 text-start">
      <div className="space-y-2">
        <img src={LogoUrl} alt="Reinspire logo" className="size-14" />
        <h1 className="font-bricolage-semibold text-2xl">Reset Password</h1>
        <p className="text-muted-foreground">
          Forgot your password? No worries! Enter your email and we'll send you
          a link to reset your password.
        </p>
      </div>
      <ForgotPasswordForm
        status={status}
        setStatus={setStatus}
        setUserEmail={setUserEmail}
      />
      <p className="text-sm text-muted-foreground">
        Back to{" "}
        <Link to="/login" className="text-primary hover:underline">
          log in
        </Link>
      </p>
    </div>
  );
};

export default ForgotPasswordView;
