import { Skeleton } from "@/components/ui/skeleton";
import { Separator } from "@/components/ui/separator";

import AccountForm from "@/views/Account/components/Form/AccountForm";
import PasswordForm from "@/views/Account/components/Form/PasswordForm";

import { useUser } from "@/api/hooks/useUser";

const SettingsView = () => {
  const { user, userLoading, userError } = useUser();

  return (
    <div className="space-y-16">
      <section>
        <h2 className="font-bricolage-medium text-lg">Account</h2>
        <p className="mb-3 text-sm text-muted-foreground">
          Update your account information
        </p>
        <Separator className="mb-6" />
        {userLoading || userError ? (
          <div className="w-full md:w-2/3 space-y-8">
            <div className="space-y-2">
              <Skeleton className="h-2.5 w-32" />
              <Skeleton className="h-10 w-full" />
            </div>
            <div className="space-y-2">
              <Skeleton className="h-2.5 w-32" />
              <Skeleton className="h-10 w-full" />
            </div>
          </div>
        ) : (
          <AccountForm user={user!} />
        )}
      </section>
      <section>
        <h2 className="font-bricolage-medium text-lg">Password</h2>
        <p className="mb-3 text-sm text-muted-foreground">
          Change your password
        </p>
        <Separator className="mb-6" />
        <PasswordForm />
      </section>
    </div>
  );
};

export default SettingsView;
