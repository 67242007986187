import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth, AuthStatus } from "@/views/Auth/AuthProvider";
import { Toaster } from "@/components/ui/toaster";
import HexagonalUrl from "@/assets/images/hexagonal.svg";

import UserImg from "@/assets/images/alex-n.png";

const AuthLayout = () => {
  const navigate = useNavigate();
  const { authState } = useAuth();

  useEffect(() => {
    if (authState.status === AuthStatus.IDLE) return;

    if (authState.status === AuthStatus.AUTHENTICATED) {
      navigate("/spaces", { replace: true });
    }
  }, [authState, navigate]);

  if (authState.status === AuthStatus.AUTHENTICATED) return null;

  return (
    <div className="relative flex h-screen max-h-screen flex-row">
      <main className="flex w-full flex-col items-center justify-center bg-card lg:w-[calc(50%-50px)]">
        <div className="w-full px-8 sm:w-2/3 sm:px-0">
          <Outlet />
        </div>
      </main>
      <aside className="relative hidden w-[calc(50%+50px)] overflow-hidden bg-primary lg:block lg:p-24 xl:p-32">
        <div className="relative flex h-full flex-col justify-between gap-16">
          <h2 className="font-bricolage-semibold text-6xl font-bold tracking-wide text-white xl:text-7xl">
            Supercharge Your Content Production
          </h2>
          <div className="ml-5 flex flex-col gap-4">
            <p className="text-md font-bricolage-medium font-semibold text-white">
              "Reinspire has been a game-changer for my social media strategy! I
              used to spend hours trying to create content, but now I can
              quickly repurpose existing material into fresh, engaging posts."
            </p>
            <div className="flex flex-row gap-4">
              <img
                src={UserImg}
                alt="Alex N."
                className="h-10 w-10 rounded-full z-10"
              />
              <div className="flex flex-col">
                <h4 className="font-bricolage-semibold text-base font-semibold text-white">
                  Alex N.
                </h4>
                <h5 className="pr-5 font-bricolage-semibold text-xs font-semibold text-white">
                  Social Media Manager
                </h5>
              </div>
            </div>
          </div>
        </div>

        {/* Decoration Hexagonals */}
        <img
          className="absolute -bottom-[116rem] -left-[24rem] size-[4000px] rotate-45"
          src={HexagonalUrl}
          alt="decoration hexagon"
        />
        <img
          className="-rotate-70 absolute -left-[35rem] -top-[145rem] size-[4000px]"
          src={HexagonalUrl}
          alt="decoration hexagon"
        />
        <img
          className="absolute -right-[22rem] -top-[48rem] size-[1800px]"
          src={HexagonalUrl}
          alt="decoration hexagon"
        />
      </aside>
      <Toaster />
    </div>
  );
};

export default AuthLayout;
