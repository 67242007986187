import { Link } from "react-router-dom";

import LoginForm from "@/views/Auth/components/Form/LoginForm";

import LogoUrl from "@/assets/images/logo-square.png";

const LoginView = () => {
  return (
    <div className="space-y-4 text-start">
      <div className="space-y-2">
        <img src={LogoUrl} alt="Reinspire logo" className="size-14" />
        <h1 className="font-bricolage-semibold text-2xl">Login to Reinspire</h1>
        <p className="text-muted-foreground">
          Welcome back! Please login to your account.
        </p>
      </div>
      <LoginForm />
      <p className="text-sm text-muted-foreground">
        Don't have an account?{" "}
        <Link to="/register" className="text-primary hover:underline">
          Sign up
        </Link>
      </p>
    </div>
  );
};

export default LoginView;
