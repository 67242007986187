import { useState, useEffect } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Paddle } from "@paddle/paddle-js";

import BillingPlanCard from "@/views/Account/components/Card/BillingPlanCard";
import EnterprisePlanCard from "@/views/Account/components/Card/EnterprisePlanCard";
import FetchError from "@/components/Error/FetchError";

import { planFeatures } from "@/views/Account/components/Utils/PlanFeatures";
import { usePrices } from "@/api/hooks/usePrices";

type BillingPlansProps = {
  subscription: Subscription | undefined;
  subscriptionLoading: boolean;
  paddle: Paddle | undefined;
};

const BillingPlans = ({
  subscription,
  subscriptionLoading,
  paddle,
}: BillingPlansProps) => {
  const [activeTab, setActiveTab] = useState("year");
  const { prices, pricesError, pricesLoading } = usePrices();

  useEffect(() => {
    if (subscription) {
      setActiveTab(subscription.current_billing_interval);
    }
  }, [subscription]);

  const renderPlanCards = (interval: "month" | "year") => {
    if (!prices) return null;
    return prices
      .filter(
        (item: PriceItem) => item.price.billing_cycle.interval === interval,
      )
      .map((item: PriceItem, index: number) => (
        <BillingPlanCard
          key={item.price.id}
          priceId={item.price.id}
          title={item.price.name}
          price={parseFloat(item.price.unit_price.amount) / 100}
          period={interval === "month" ? "monthly" : "yearly"}
          discount={interval === "year" ? 25 : undefined}
          activeSubscription={
            item.price.id === subscription?.items[0].paddle_price_id &&
            subscription?.status !== "canceled"
              ? subscription
              : null
          }
          isUserSubscribed={subscription?.status !== "canceled"}
          features={planFeatures[index]}
          paddle={paddle}
        />
      ));
  };

  const renderContent = () => {
    if (pricesLoading || subscriptionLoading) {
      return (
        <div className="flex w-full flex-row flex-wrap items-center justify-center gap-8">
          {[...Array(3)].map((_, i) => (
            <Skeleton
              key={i}
              className="h-96 w-[300px] rounded-sm border border-border"
            />
          ))}
        </div>
      );
    }

    if (pricesError) {
      return <FetchError />;
    }

    return (
      <>
        <TabsContent
          value="month"
          className="mt-0 flex flex-row flex-wrap justify-center gap-8"
        >
          {renderPlanCards("month")}
        </TabsContent>
        <TabsContent
          value="year"
          className="mt-0 flex flex-row flex-wrap justify-center gap-8"
        >
          {renderPlanCards("year")}
        </TabsContent>
      </>
    );
  };

  return (
    <>
      <section id="plans">
        <Tabs
          value={activeTab}
          onValueChange={(value) => setActiveTab(value)}
          className="space-y-3"
        >
          <div className="flex flex-row items-center justify-between gap-4">
            <div className="flex flex-col">
              <h2 className="font-bricolage-medium text-lg">Change Plan</h2>
              <p className="hidden text-sm text-muted-foreground md:block">
                Choose the plan that suits your needs — Yearly plans are 25%
                off!
              </p>
            </div>
            <TabsList className="relative bg-secondary">
              <TabsTrigger value="month" className="font-bricolage-regular">
                Monthly
              </TabsTrigger>
              <TabsTrigger value="year" className="font-bricolage-regular">
                Yearly
              </TabsTrigger>
            </TabsList>
          </div>
          <Separator className="!mb-6" />
          <div className="flex items-center justify-center">
            {renderContent()}
          </div>
        </Tabs>
      </section>
      <section id="enterprise" className="space-y-3">
        <div className="flex flex-row items-center justify-between">
          <div>
            <h2 className="font-bricolage-medium text-lg">Enterprise</h2>
            <p className="hidden text-sm text-muted-foreground md:block">
              Need something custom? Contact us for a quote.
            </p>
          </div>
          <div>
            <Button asChild>
              <a href="mailto:support@reinspire.io">Contact us</a>
            </Button>
          </div>
        </div>
        <Separator className="!mb-6" />
        <EnterprisePlanCard />
      </section>
    </>
  );
};

export default BillingPlans;
